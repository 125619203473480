import MainContent from "./mainContent";
import React, { useEffect, useState,useMemo } from 'react'
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { callMsGraph } from '../graph';
import { loginRequest } from '../authConfig';
import {getMemberInfoFromURL} from "./getURLParameters"
import {getAllapps} from './dbUtils'

const ginit = {givenName: "", surname: ""}
const INIT_MEMBERINFO = { appid: "uncheck", memberid: "", mobilephone: "",opt: "",firstname: "",lastname: "" }

//This function checks if the user is athenticated and pass the user login info to the MainContent component
const MainInfo = () => {

   const [memberInfo, setMemberInfo] = useState(INIT_MEMBERINFO)
   const { accounts,instance, inProgress } = useMsal()
   const [graphData, setGraphData] = useState(ginit)
   const isAuthenticated = useIsAuthenticated()
   const [pageerr, setPageErr] = useState(true)
   const [err, setErr] = useState("")
   const [allAppIDs, setAllAppIDs] = useState([])
   const INVALID_REQUEST="Invalid Request!!!"
   const NO_APPOINTMENT="No appointment reminder history!!!"

   useEffect(() => {
      async function fetch() { 
         const getAllAppIDs = await getAllapps()
         setAllAppIDs(getAllAppIDs)      
         if (member.datastatus === "checked"){
            if ((member.appid ==="") || (member.memberid ==="")) {
            setErr(INVALID_REQUEST)
            } 
            else
            {  
               if (getAllAppIDs !== undefined && getAllAppIDs.length !== 0){
                  if (getAllAppIDs.includes(member.appid)) {
                     setPageErr(false)
                  }
                  else {
                     setErr(INVALID_REQUEST)
                  }
               } else 
               { 
                  setErr(INVALID_REQUEST) 
               }
            }
         }
      }

      const member =  getMemberInfoFromURL()
      console.log("MEMBERINFO JS member", member)
      setMemberInfo(member)
      fetch();
      if (inProgress === InteractionStatus.None && !isAuthenticated) {
         instance.loginRedirect(loginRequest);
      }
     if (isAuthenticated) {
         instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
         })
         .then((response) => { 
            callMsGraph(response.accessToken).then((response) =>{ 
               setGraphData(response)});
         });;
     }
   }, [])

return ( 
   <div>
   {pageerr && <h1>{err}</h1>}
   {!pageerr && <MainContent memberInfo={memberInfo} ingraphData={graphData}></MainContent> }
   </div>
)
}

export default MainInfo;