/* src/App.js */
//import MainContent from './components/mainContent'
import React, { useEffect, useState,useMemo } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MainInfo from './components/mainInfo'
import MainContent from './components/mainContent';
import { loginRequest } from './authConfig';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";


// This is the main function to populate the web page body with authentication
// 
const App = () => {
  const adgroup =process.env.REACT_APP_AD_GROUP
  const isAuthenticated = useIsAuthenticated();
  const { accounts,instance, inProgress } = useMsal();
  const [authorized, setAuthorized] = useState(false);
  //const { instance, accounts } = useMsal();
  const [ssoreq, setSsoreq] = useState(false);

  if (inProgress === InteractionStatus.None && !isAuthenticated) { 
    instance.loginRedirect(loginRequest);
  }

  useEffect(() => {   
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        console.log("RESPONSE",response)
        if (
          !response ||
          !response.idTokenClaims ||
          !response.idTokenClaims.groups
        ) {
          setAuthorized(false);
        } else {
          setAuthorized(
            response.idTokenClaims.groups.includes(adgroup)
          );
        }
      });;
   }, [])

  return (
  < HelmetProvider >  
          <div>{authorized}</div>
          <AuthenticatedTemplate>
            {authorized && <MainInfo></MainInfo>}       
          </AuthenticatedTemplate>
    
          <UnauthenticatedTemplate>          
                {/* <h5>
                    <center>
                        Unauthorized.
                    </center>
                </h5>  */}
          </UnauthenticatedTemplate>

   </HelmetProvider> 

  )
}

export default App
