/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      appid
      frequency
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      appid
      frequency
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      appid
      frequency
      status
      createdAt
      updatedAt
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      appid
      memberid
      createdAt
      event
      mobilephone
      message
      status
      actionby
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      appid
      memberid
      createdAt
      event
      mobilephone
      message
      status
      actionby
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      appid
      memberid
      createdAt
      event
      mobilephone
      message
      status
      actionby
      updatedAt
    }
  }
`;
export const createApptdetail = /* GraphQL */ `
  mutation CreateApptdetail(
    $input: CreateApptdetailInput!
    $condition: ModelApptdetailConditionInput
  ) {
    createApptdetail(input: $input, condition: $condition) {
      id
      reminderdatetime
      appointmentdatetime
      memberid
      status
      appid
      createdAt
      updatedAt
    }
  }
`;
export const updateApptdetail = /* GraphQL */ `
  mutation UpdateApptdetail(
    $input: UpdateApptdetailInput!
    $condition: ModelApptdetailConditionInput
  ) {
    updateApptdetail(input: $input, condition: $condition) {
      id
      reminderdatetime
      appointmentdatetime
      memberid
      status
      appid
      createdAt
      updatedAt
    }
  }
`;
export const deleteApptdetail = /* GraphQL */ `
  mutation DeleteApptdetail(
    $input: DeleteApptdetailInput!
    $condition: ModelApptdetailConditionInput
  ) {
    deleteApptdetail(input: $input, condition: $condition) {
      id
      reminderdatetime
      appointmentdatetime
      memberid
      status
      appid
      createdAt
      updatedAt
    }
  }
`;
export const createQueue = /* GraphQL */ `
  mutation CreateQueue(
    $input: CreateQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    createQueue(input: $input, condition: $condition) {
      id
      memberid
      appointmentdatetime
      appid
      functionid
      actionby
      createdAt
      updatedAt
    }
  }
`;
export const updateQueue = /* GraphQL */ `
  mutation UpdateQueue(
    $input: UpdateQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    updateQueue(input: $input, condition: $condition) {
      id
      memberid
      appointmentdatetime
      appid
      functionid
      actionby
      createdAt
      updatedAt
    }
  }
`;
export const deleteQueue = /* GraphQL */ `
  mutation DeleteQueue(
    $input: DeleteQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    deleteQueue(input: $input, condition: $condition) {
      id
      memberid
      appointmentdatetime
      appid
      functionid
      actionby
      createdAt
      updatedAt
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      memberid
      firstname
      lastname
      mobilephone
      appid
      opt
      createdAt
      updatedAt
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      memberid
      firstname
      lastname
      mobilephone
      appid
      opt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      memberid
      firstname
      lastname
      mobilephone
      appid
      opt
      createdAt
      updatedAt
    }
  }
`;
