import {getAllapps} from './dbUtils'

//This function extract all query string from URL
//Get all appid from settings_table
async function validAppID(tempstr) {
  var result = true
  if (tempstr !== undefined && tempstr !== null)
  {
  var tempAppID = tempstr.toUpperCase()
  const allAppIDs = await getAllapps()
    if (allAppIDs.includes(tempAppID))
    { 
      result = false
    } 
  }   
  return result
 }

export const getMemberInfoFromURL = () => {
    var member = { appid: "uncheck",firstname: "", memberid: "", lastname: "", mobilephone: "",opt: "",datastatus:"uncheck"}
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has("memberid"))
    {
      member.memberid = queryParams.get("memberid")
    }
    if (queryParams.has("appid"))
    {
       var tempstr = queryParams.get("appid")
      // if (tempstr !== undefined && tempstr !== null)
      // {
       var tempAppID = tempstr.toUpperCase()
      // const allAppIDs = await getAllapps()
      //  if (allAppIDs.includes(tempAppID))
      //  { 
           member.appid = tempstr.toUpperCase()
      //  } 
      //  else {
      //     member.appid =""
      //  }
      // }   
    }
    else
    {
      member.appid =""
    }
    if (queryParams.has("firstname"))
    {
      member.firstname = queryParams.get("firstname")
    }
    if (queryParams.has("lastname"))
    {
      member.lastname = queryParams.get("lastname")
    }
    if (queryParams.has("mobilephone"))
    {
      member.mobilephone = queryParams.get("mobilephone")
    }
    member.datastatus="checked"
    return member
}
