import React, { useEffect, useState,useMemo } from 'react'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import awsExports from "../aws-exports";
import logo from '../img/CalSTRSLogoRed200.png';
import Table from "./Table"
import {updateMemberInfo,addMemberinfo, findHistoryByMemberid, addHistory, findMembersByMemberid,findMembersByPhone} from './dbUtils'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../App.css'
import {ConvertTo10DigPhone,formatPhone} from './utils'
import { PatternFormat } from 'react-number-format';
Amplify.configure(awsExports);

const initialMember = { memberid: '', mobilephone: '',opt: '',firstname: "uncheck",lastname: "" }
const ginit = {givenName: '', surname: ''}
var viewoption = {appError: false}

var memberid=""
var NO_APPOINTMENT="No appointment reminder history"
var appid=""
var msg=""

//This function will pull all the information from database and populate the page
const MainContent = (props) => {  
  const queryParams = new URLSearchParams(window.location.search)

  const [staffname, setStaffname] = useState("")
  const [member, setMember] = useState(initialMember)
  const [histories, setHistories] = useState([])
  const [disableUpdate, setDisableUpdate]=useState(true)
  const [saveOrUpdate, setSaveOrUpdate] = useState("")
  const [optCheck, setOptCheck] = useState(false)
  const [saveOrUpdateClick, setSaveOrUpdateClick] = useState(false)
  const [enableSaveOrUpdate, setEnableSaveOrUpdate] = useState(false)
  const [mobilePhone, setMobilePhone] = useState("")
  const memberInfo = props.memberInfo
  const [pageerr, setPageerr] = useState(true);
  const [noAppointmenterr, setNoAppointmenterr] = useState(true);

  console.log("--- memberInfo",memberInfo)
  memberid = memberInfo.memberid
  member.memberid = memberInfo.memberid
  const urlappid = memberInfo.appid
  console.log("--- urlappid",urlappid)
  const urlappiduc = urlappid.toUpperCase()
 
  const onPhoneChange = (value) => {  
    console.log("value ", value)
    setMobilePhone(value.formattedValue)
    setEnableSaveOrUpdate(true)
    setSaveOrUpdateClick(false)
    setDisableUpdate(false)
  }
  useEffect(() => {
      setPageerr(true)
      msg=""
      async function fetch() {
          await fetchMembers()
          await fetchHistories()
      }
      fetch();
  }, [saveOrUpdate])

  function setInput(key, value) {
    setMember({ ...member, [key]: value })
  }

  async function fetchMembers() {
    const memberData = await findMembersByMemberid(memberInfo.memberid)
    
    const members = memberData.data.membersSortByDate.items
    console.log("TOPPPP MEMBERS ARRAY:", members)
    var fetchmember = null
    console.log("MEMBERS ARRAY:", members)
    console.log("MEMBERS ARRAY fetchmember:", fetchmember)
    members.forEach(membr => {
      var appid = membr.appid
      console.log("return APPID :",appid)
      var appiduc = appid.toUpperCase()
      console.log("return APPIDUC :",appiduc)
      console.log(" URLAPPIDUC :",urlappiduc)
       if(appiduc === urlappiduc){
         fetchmember =membr
         }
       });
    setMobilePhone("")
   if (fetchmember != null)
     {

    console.log("MEMBERS === ARRAY:", members)
     setSaveOrUpdate("Update")
     setMember(fetchmember) 
     setPageerr(false)  
     setNoAppointmenterr(false)  
     setMobilePhone(formatPhone(fetchmember.mobilephone))
     
        if (member.opt === "Opt-in"){
          setOptCheck(false)    
        }
        else
        {
          setOptCheck(true)
        }
     }
     else
     {
      setSaveOrUpdate("Save")
      member.opt = optCheck
      setMember(memberInfo)
      viewoption.appError=true
      if (member.firstname !=="uncheck")
      {
        setPageerr(true)
        setNoAppointmenterr(true)
      } 
      msg =NO_APPOINTMENT
     }
     setDisableUpdate(true)
  }

  async function fetchHistories() {
        //const appid="myCalSTRS"
        var histories=[]
        console.log("=======memberInfo.memberid",memberInfo.memberid)
        const foundhistories = await findHistoryByMemberid(memberInfo.memberid);
        console.log("-------foundhistories",foundhistories)
        foundhistories.forEach(hist => {
          var appid = hist.appid
          console.log("return APPID :",appid)
          var appiduc = appid.toUpperCase()
          console.log("return APPIDUC :",appiduc)
          console.log(" URLAPPIDUC :",urlappiduc)
           if(appiduc === urlappiduc){
             histories.push(hist)
             console.log("SET MEMBR......")
             }
           });
        if (histories.length >0)
        {
           setHistories(histories)
        }
  }

  //This function will update the database tables reflect to the change on the page
  async function modifyMember() {
    member.mobilephone= ConvertTo10DigPhone( mobilePhone)
    var testr = props.ingraphData.givenName+" "+props.ingraphData.surname
   
    setStaffname(testr)
    console.log("PHONE NUMBER UPDATE:", member.mobilephone)
    if( !(member.mobilephone.match('[0-9]{10}')) )
    {
      alert('Please provide valid phone number');
    }else
    {
      if (saveOrUpdate == "Save")
      {
        await addMemberinfo(member)
        setSaveOrUpdate("Update")
      }
      if (saveOrUpdate == "Update" )
      {
        await updateMemberInfo(member)  
        const memberHaveSamePhoneData = await findMembersByPhone(member.mobilephone)
        const memberHaveSamePhone = memberHaveSamePhoneData.data.membersPhoneSortByDate.items
        console.log("MEMBERS have SAME PHONE:", memberHaveSamePhone)
        var membersNeedUpdate =[]
        memberHaveSamePhone.forEach(memb => {
           if ((memb.appid !== member.appid) || (memb.memberid !== member.memberid))
           {
             const changeMemberinfo = {
               memberid: memb.memberid,
               id: memb.id,
               mobilephone: memb.mobilephone,
               opt: member.opt,
               firstname: memb.firstname,
               lastname: memb.lastname,
               appid: memb.appid,
             };
            membersNeedUpdate.push(changeMemberinfo)
           }
           }
        )
        for (let i = 0; i < membersNeedUpdate.length; i++) {

          await updateMemberInfo(membersNeedUpdate[i])
          await addHistory(membersNeedUpdate[i],testr)
        }
      }
      setSaveOrUpdateClick(true)
      await addHistory(member,testr)
      await fetchHistories()
    }
    setDisableUpdate(true)
  }
  
  const changeOptHandle = (e) => {
    setOptCheck(e.target.checked)
    setSaveOrUpdateClick(false)
    if (e.target.checked){
      setInput("opt", "Opt-out")
    }
    else{
      setInput("opt", "Opt-in")   
    }
    setSaveOrUpdateClick(false)
    setEnableSaveOrUpdate(true)
    setDisableUpdate(false)
  }

  return (
    < HelmetProvider > 
      { pageerr && <h1>{msg}</h1>}
      { (!pageerr)  &&  !noAppointmenterr &&
          <div>       
            <div>
              <Helmet>
                <meta charset="utf-8" />
                <title>SMS Appointment Reminders</title>    
              </Helmet>  
            </div> 
            <div className="body" >
              <div className="container" >
                <div>
                  <nav>
                  <img src={logo} alt="CalSTRS" ></img>
                </nav>
                </div>
                  <h1 className="center">SMS Appointment Reminders</h1>          
                  <div className="card">   
                    <div> 
                        <div className="card-header"><h2>Member's Information</h2></div>
                        <div className="card-body">
                          {saveOrUpdateClick && <div className="alert alert-success" role="alert">Your changes have been {saveOrUpdate}d.</div>}
                          <div className="label_text"><label htmlFor="lmemberid" id="lmemberid">Member ID:</label></div>
                          <div className="label_info"><label htmlFor="imemberid" id="imemberid">{member.memberid}</label></div>
                          <br></br>
                          <div className="label_text"><label htmlFor="lfirstName" id ="lfirstName">First name:</label></div>
                          <div className="label_info"><label htmlFor="ifirstname" id="ifirstname">{member.firstname}</label></div>
                          <br></br>
                          <div className="label_text">
                          <label htmlFor="llastname" id="llastname">Last name:</label></div>
                          <div className="label_info"><label htmlFor="ilastname" id="ilastname">{member.lastname}</label></div>
                          <br></br>
                          <div className="label_text"><label htmlFor="lphone" id="lphone">Mobile phone:</label> </div>
                          <div className="label_info">
                            <PatternFormat className="body" data-testid="phone"  id="mobilePhone"
                              type="tel" format="(###) ###-####" mask="_" placeholder={mobilePhone}
                                value={mobilePhone} onValueChange={onPhoneChange} autoComplete="on"
                              />
                          </div>
                          <br></br>
                          <div className="label_text"><input type="checkbox" id="optOut"  checked={optCheck} onChange={changeOptHandle}></input></div> 
                          <div className="label_info"><label htmlFor="optOut" >Member does not want to receive SMS messages.</label></div>
                          <br></br>
                          {(enableSaveOrUpdate && !disableUpdate) && 
                            <div className="center">
                             
                              <button  data-testid="buttonupdate" className="appButton" type="button" onClick={modifyMember}>{saveOrUpdate}</button>

                            </div>
                          }
                          <br></br>
                        </div> 
                    </div>              
                    <p>&nbsp;</p>                    
                    <div>
                        <div className="h2"> SMS History</div>           
                        <Table histories={histories} memberid={member.memberid}/>                
                    </div>
                </div>     
              </div>
            </div>
          </div>
      }
        
    </HelmetProvider>     
  )
}

export default MainContent
