import './table.css'
import { useTable } from "react-table";
import React, { useEffect, useState } from 'react'
import { Amplify } from 'aws-amplify'
import { Moment } from 'moment';
import {ConvertPSTTime} from './utils'

//This function will display a table with passing parameters
export default function Table(props) {
 
  // Use the useTable Hook to send the columns and data to build the table
 // console.log("table props",props)
 // console.log("table props.histories",props.histories)
 // console.log("table props.memeberid",props.memberid)
  const [th, setTH] = useState([])
    const datain = props.histories
   // console.log("DATAIN :", datain)
    var data = ConvertPSTTime(datain)
    //setTH(data)
    //console.log("IN ====data",data)

  const columns = React.useMemo(
    () => [
      {
        Header: "Timestamp",
        accessor: "createdAt",
      },
      {
        Header: "Event",
        accessor: "event",
      },
      {
        Header: "AppID",
        accessor: "appid",
      },
      {
        Header: "Mobile phone",
        accessor: "mobilephone",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Updated by",
        accessor: "actionby",
      },
      {
        Header: "Message",
        accessor: "message",
      },

    ],
    []
  );


    
  useEffect(() => {
    setTH(data)
  }, [])


  //const data = props.histories

  // data.map((item,i) => {
  //   console.log("timestamp",item.timestamp)
  //   console.log("Mom timestamp",Moment.tz(1412144245453, 'America/Los_Angeles').format('MM/DD/YYYY h:mm a'))
    
  // })
  //console.log("TABLE data",data)
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (

    <table {...getTableProps()} >
      <thead> 
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {(rows.length > 0) && rows.map((row, i) => {
          prepareRow(row);
          return (
             <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        }) 
         
        
      } 
        {(rows.length == 0) &&<tr><td>No items to display</td></tr>}
      </tbody>
       
    </table>
  );
}

