//import { Moment } from "moment";
import moment from "moment-timezone";
export function formatPhone(phoneNumberString) { 
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return "";
  }


export function ConvertTo10DigPhone(phoneNumberString) { 
   return phoneNumberString.replace(/\D/g,'');   
}

export function ConvertPSTTime(arrayin) { 
  
  var arrayout= arrayin
arrayout.forEach(d => {
  moment.tz(d.createdAt, 'America/Los_Angeles').format('MM/DD/YYYY h:mm a')
  d.createdAt = moment(d.createdAt).tz('America/Los_Angeles').format('MM/DD/YYYY h:mm A') 
})

 return arrayout
}