/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      appid
      frequency
      status
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appid
        frequency
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!, $appid: String!, $memberid: String!) {
    getHistory(id: $id, appid: $appid, memberid: $memberid) {
      id
      appid
      memberid
      createdAt
      event
      mobilephone
      message
      status
      actionby
      updatedAt
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $id: ID
    $appidMemberid: ModelhistoryPrimaryCompositeKeyConditionInput
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHistories(
      id: $id
      appidMemberid: $appidMemberid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        appid
        memberid
        createdAt
        event
        mobilephone
        message
        status
        actionby
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApptdetail = /* GraphQL */ `
  query GetApptdetail($id: ID!) {
    getApptdetail(id: $id) {
      id
      reminderdatetime
      appointmentdatetime
      memberid
      status
      appid
      createdAt
      updatedAt
    }
  }
`;
export const listApptdetails = /* GraphQL */ `
  query ListApptdetails(
    $filter: ModelApptdetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApptdetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reminderdatetime
        appointmentdatetime
        memberid
        status
        appid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQueue = /* GraphQL */ `
  query GetQueue($id: ID!) {
    getQueue(id: $id) {
      id
      memberid
      appointmentdatetime
      appid
      functionid
      actionby
      createdAt
      updatedAt
    }
  }
`;
export const listQueues = /* GraphQL */ `
  query ListQueues(
    $filter: ModelQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberid
        appointmentdatetime
        appid
        functionid
        actionby
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      memberid
      firstname
      lastname
      mobilephone
      appid
      opt
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberid
        firstname
        lastname
        mobilephone
        appid
        opt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const historiesSortByDate = /* GraphQL */ `
  query HistoriesSortByDate(
    $memberid: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelhistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    historiesSortByDate(
      memberid: $memberid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appid
        memberid
        createdAt
        updatedAt
        event
        mobilephone
        message
        status
        actionby
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const apptDetailSortByDate = /* GraphQL */ `
  query ApptDetailSortByDate(
    $memberid: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelapptdetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apptDetailSortByDate(
      memberid: $memberid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reminderdatetime
        createdAt
        updatedAt
        appointmentdatetime
        memberid
        status
        appid
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queueSortByDate = /* GraphQL */ `
  query QueueSortByDate(
    $memberid: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelqueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queueSortByDate(
      memberid: $memberid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberid
        createdAt
        updatedAt
        appointmentdatetime
        appid
        functionid
        actionby
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const membersSortByDate = /* GraphQL */ `
  query MembersSortByDate(
    $memberid: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelmemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersSortByDate(
      memberid: $memberid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberid
        createdAt
        updatedAt
        firstname
        lastname
        mobilephone
        appid
        opt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const membersPhoneSortByDate = /* GraphQL */ `
  query MembersPhoneSortByDate(
    $mobilephone: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelmemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersPhoneSortByDate(
      mobilephone: $mobilephone
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberid
        createdAt
        updatedAt
        firstname
        lastname
        mobilephone
        appid
        opt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
