import {createHistory,createMember,updateMember} from '../graphql/mutations'
import { listMembers,historiesSortByDate,listSettings,listHistories,membersSortByDate,membersPhoneSortByDate } from '../graphql/queries'
import { Amplify, API, graphqlOperation } from 'aws-amplify'

//This function logs the history
export async function addHistory(member,staffname) {
    const historyinfo = {
      memberid: member.memberid,
      mobilephone: member.mobilephone,
      event: member.opt,
      appid: member.appid,
      status: "success",
      actionby: staffname
    };
    try {
      await API.graphql(graphqlOperation(createHistory, {input: historyinfo}))
    } catch (err) {
      console.log('error creating history:', err)
    }    
}

//This funtion update the memberTable
export async function updateMemberInfo(member) {
    const memberDetails = {
      id: member.id,
      appid: member.appid,
      memberid: member.memberid,
      mobilephone: member.mobilephone,
      opt: member.opt,
      firstname: member.firstname,
      lastname: member.lastname
    };
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^details ",memberDetails)
    try {
      if (!member.firstname || !member.memberid) return
      await API.graphql(graphqlOperation(updateMember, {input: memberDetails}))
    } catch (err) {
      console.log('error updating member:', err)
    }
  }

//Get all appid from settings_table
export async function getAllapps() {
 //const filter = {}
 let appids =[]
 var tempstr =""
  try {
    const settingData = await API.graphql(graphqlOperation(listSettings))
    const settings = settingData.data.listSettings.items;
    settings.map((item, index) => 
    {
      tempstr=item.appid
      appids.push(tempstr.toUpperCase())
    }  )
   return appids    
  } catch (err) { console.log('error getting _appIDs',err) }
}
    
//This function add new member info
 export async function addMemberinfo(member) {
    const memberinfo = {
      memberid: member.memberid,
      mobilephone: member.mobilephone,
      opt: member.opt,
      firstname: member.firstname,
      lastname: member.lastname,
      //id: new Date().getTime(),
     // aapid: member.appid,
     // createdAt: moment(new Date()).toISOSamplitring(),
     // updatedAt: moment(new Date()).toISOString()
    };
    try {
      if (!member.firstname || !member.memberid) return
      await API.graphql(graphqlOperation(createMember, {input: memberinfo}))
    } catch (err) {
      console.log('error creating todo:', err)
    }
 }

 //This function pulls all the SMS histories for a given memberId
  export async function findHistoryByMemberid(memberid) {
    const filter = {memberid: {eq: memberid }}
    console.log("*********filter:",filter)
    const inputParms = { 
      memberid: memberid,
      sortDirection: "DESC"
    }
     try {
      console.log("filter:",filter)
      const historyData = await API.graphql(graphqlOperation(historiesSortByDate,inputParms))
      // const historyData = await API.graphql(graphqlOperation(listHistories,{filter:filter}))
      console.log("***********historyData:",historyData)
      var histories = historyData.data.historiesSortByDate.items
      histories.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)
       return histories
      } 
      catch (err) { console.log('error fetching histories') }
  }

  //This retrieve member info for a given memberId
  export async function findMembersByMemberid(memberid) {
  //  const filter = {memberid: {eq: memberid }}
   const inputParms = { 
    memberid: memberid,
    sortDirection: "DESC"
  }   //const filter = [{memberid: {eq: memberid }}, {appid: {eq: appid }}]
    try {
      // const memberData = await API.graphql(graphqlOperation(listMembers,{filter:filter}))
      const memberData = await API.graphql(graphqlOperation(membersSortByDate,inputParms))
     console.log("findMembersByMemberid memberData: ", memberData)
     return memberData    
    } catch (err) { console.log('error fetching members') }
  }

export async function findMembersByPhone(phone) {
  // const filter = {mobilephone: {eq: phone }}
  const inputParms = { 
    mobilephone: phone,
    sortDirection: "DESC"
  }  
   try {
    // const memberData = await API.graphql(graphqlOperation(listMembers,{filter:filter}))
    const memberData = await API.graphql(graphqlOperation(membersPhoneSortByDate,inputParms))
    console.log("findMembersByPhone memberData: ", memberData)
    return memberData
   }
   catch (err) { console.log('error fetching members by phone') }  
}